import {
  FinalGrades,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Box, VStack } from '@revolut/ui-kit'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import React, { useMemo } from 'react'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'

export const ReviewDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_REVIEW.DELIVERABLES,
}

export const ReviewLayoutDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_REVIEW_LAYOUT,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_REVIEW_LAYOUT,
}

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
  [ButtonStates.Continue]: 'Pencil' as const,
}

export const CheckpointStepReviewers = ({
  checkpoint,
  requests,
  grades,
  reviewCategory,
  performanceLink,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  requests?: PerfReviewRequestFeedbackInterface[]
  grades?: FinalGrades[]
  performanceLink?: string
}) => {
  const reviews: GradedRequestInterface[] | undefined = useMemo(
    () =>
      requests
        ?.filter(
          item => item.probation_checkpoint?.number === checkpoint.checkpoint_number,
        )
        .map(item => {
          const gradeValue = grades?.find(rating => rating.review.id === item.id)

          return {
            ...item,
            grade: gradeValue?.value,
            updated_date:
              item.status === Statuses.completed ? item.updated_date : undefined,
          }
        }),
    [checkpoint, grades, requests],
  )

  if (!reviews?.length) {
    return null
  }

  const buttonDisabled = !checkpoint?.can_be_submitted

  const onClickAddReview = () => {
    const id = checkpoint.review?.id
    const employeeId = checkpoint.review?.reviewed_employee?.id
    if (id && employeeId) {
      const path = ReviewLayoutDeliverablesPath[reviewCategory]
      navigateTo(
        pathToUrl(path, {
          id,
          employeeId,
        }),
      )
    }
  }

  const userReviewStatus = checkpoint?.review?.status
  let state = ButtonStates.Add
  if (userReviewStatus === Statuses.completed) {
    state = ButtonStates.Edit
  }
  if (userReviewStatus === Statuses.draft) {
    state = ButtonStates.Continue
  }

  return (
    <Box width="100%">
      <VStack gap="s-8">
        {reviews && (
          <StepReviewersList
            reviews={reviews}
            onClickAddReview={onClickAddReview}
            buttonDisabled={buttonDisabled}
            icon={ButtonIcon[state]}
            title={ButtonTitles[state]}
            performanceLink={performanceLink}
          />
        )}
      </VStack>
    </Box>
  )
}
