import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { OptionInterface } from '@src/interfaces/selectors'
import { Avatar, Bar, FilterButton, Icon, Token } from '@revolut/ui-kit'
import { useQuery } from '@src/utils/queryParamsHooks'
import { intersection } from 'lodash'
import { AvatarType } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import FilterSelect from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { ReviewerRelation } from '@src/interfaces/performance'

interface Props {
  options: OptionInterface[]
  quickFilters: QuickFilter[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  hideDropdown?: boolean
  showFilters?: boolean
  hideAll?: boolean
  className?: string
  filterKey: string
  labelAll?: string
  setActiveFilters?: (activeAvatars: QuickFilter[]) => void
}

const Label = styled.div`
  font-size: 13px;
  margin-right: 8px;
`
const ChipContainer = styled.div``
const Container = styled.div`
  display: flex;
  align-items: center;
`

const ClearFilter = styled.button`
  font-size: 14px;
  color: ${Token.color.blue};
  margin-left: 8px;
`

export interface QuickFilter {
  full_name?: string
  key: ReviewerRelation | string
  name: string
  filters: number[]
  avatar?: AvatarType | null
}

const Filter = ({
  options,
  setActiveFilters,
  quickFilters,
  onFilterChange,
  prefix,
  showFilters,
  hideAll,
  className,
  filterKey,
  hideDropdown,
  isClearable,
  labelAll = 'All',
}: Props) => {
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const { query, changeQueryParam, deleteQueryParam } = useQuery()
  const [openFilter, setOpenFilter] = useState(false)
  const queryValues = query[filterKey]?.split(',').map(Number)

  const optionsFunc = async (): Promise<{
    options: OptionInterface[]
  }> => {
    return {
      options,
    }
  }

  useEffect(() => {
    if (!queryValues) {
      setActiveFilters && setActiveFilters(quickFilters)
    }
  }, [quickFilters])

  const clearFilter = () => {
    deleteQueryParam(filterKey)
    if (onFilterChange) {
      onFilterChange(undefined)
    }
  }

  const toggleFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handleFilterChange = (chosenFilters: OptionInterface[]) => {
    if (!chosenFilters.length) {
      deleteQueryParam(filterKey)
      setActiveFilters && setActiveFilters(quickFilters)
      clearFilter()

      return
    }
    if (setActiveFilters) {
      const chosenFiltersRelations = chosenFilters.map(({ relation }) => relation)

      const activeFilters = quickFilters.filter(quickFilter =>
        chosenFiltersRelations.includes(quickFilter.name),
      )
      setActiveFilters(activeFilters)
    }

    changeQueryParam(filterKey, chosenFilters.map(fil => fil.id).join(','))
    if (onFilterChange) {
      onFilterChange(chosenFilters)
    }
    toggleFilter()
  }

  const applyQuickFilter = (relation: string, isSelected: boolean) => {
    if (isSelected) {
      clearFilter()
      return
    }

    const filtersList = quickFilters.find(f => f.key === relation)!.filters
    const chosenFilters = options.filter(o => filtersList.includes(+o.id))

    changeQueryParam(filterKey, chosenFilters.map(fil => fil.id).join(','))
    if (onFilterChange) {
      onFilterChange(chosenFilters)
    }
  }

  const label =
    queryValues && queryValues.length
      ? `Selected · ${queryValues.length}`
      : `All reviewers`
  const isSelectAll = options.some(o => queryValues?.includes(+o.id))

  return (
    <Container className={className} data-testid={`filter-${filterKey}`}>
      {prefix && <Label>{prefix}</Label>}
      {showFilters && (
        <Bar>
          {!hideAll && (
            <FilterButton
              pl="s-4"
              aria-pressed={!queryValues}
              useIcon={
                <Avatar
                  size={32}
                  useIcon={<Icon name="People" size={20} color={Token.color.onAccent} />}
                />
              }
              onClick={() => {
                clearFilter()
                setActiveFilters && setActiveFilters(quickFilters)
              }}
              active={!queryValues}
            >
              {labelAll}
            </FilterButton>
          )}
          {quickFilters.map(quickFilter => {
            if (!quickFilter.filters.length) {
              return null
            }

            const isSelected = !!intersection(queryValues, quickFilter.filters).length
            return quickFilter.avatar || quickFilter.full_name ? (
              <FilterButton
                aria-pressed={isSelected}
                pl="s-4"
                useIcon={
                  <UserWithAvatar
                    pr="s-8"
                    size={32}
                    uuid={quickFilter.full_name}
                    id={quickFilter.key}
                    full_name={quickFilter.full_name}
                    avatar={quickFilter.avatar}
                    compact
                    asText
                  />
                }
                key={quickFilter.key}
                onClick={() => {
                  applyQuickFilter(quickFilter.key, isSelected)
                  setActiveFilters && setActiveFilters([quickFilter])
                }}
                active={isSelected}
              >
                {quickFilter.name}
              </FilterButton>
            ) : (
              <FilterButton
                key={quickFilter.key}
                onClick={() => {
                  applyQuickFilter(quickFilter.key, isSelected)
                  setActiveFilters && setActiveFilters([quickFilter])
                }}
                aria-pressed={isSelected}
                active={isSelected}
              >
                {quickFilter.name}
              </FilterButton>
            )
          })}
          {!hideDropdown && (
            <ChipContainer ref={popoverAnchor}>
              <FilterButton
                onClick={toggleFilter}
                active={isSelectAll}
                useIcon="Filter"
                aria-pressed={isSelectAll}
              >
                {label}
              </FilterButton>
            </ChipContainer>
          )}
        </Bar>
      )}
      {popoverAnchor.current && (
        <FilterSelect
          anchorRef={popoverAnchor}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          value={options.filter(o => queryValues?.includes(+o.id))}
          onChange={handleFilterChange}
          selector={optionsFunc}
        />
      )}
      {isClearable && !!queryValues ? (
        <ClearFilter type="button" onClick={clearFilter}>
          Clear Filter
        </ClearFilter>
      ) : null}
    </Container>
  )
}

export default Filter
