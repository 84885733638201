import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { Box, StatusWidget, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import { getPayCycleEmployeeReportsTableRequests } from '@src/api/payrollV2'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import {
  payCycleReportsChangesColumn,
  payCycleReportsDepartmentColumn,
  payCycleReportsEmployeeNameColumn,
  payCycleReportsGrossSalaryColumn,
  payCycleReportsRoleColumn,
  payCycleReportsStatusColumn,
} from '@src/constants/columns/payCycleReportsV2'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
} from '@src/interfaces/payrollV2'

import { CycleSelector } from './CycleSelector'
import { CommonTabProps } from '../common'

const getRow = (
  onRowClick: (id: number) => void,
): RowInterface<PayCycleReportTableItemInterface> => ({
  linkToForm: data => {
    onRowClick(data.id)
  },
  cells: [
    {
      ...payCycleReportsEmployeeNameColumn,
      width: 200,
    },
    {
      ...payCycleReportsGrossSalaryColumn,
      width: 100,
    },
    {
      ...payCycleReportsRoleColumn,
      width: 150,
    },
    {
      ...payCycleReportsDepartmentColumn,
      width: 150,
    },
    {
      ...payCycleReportsChangesColumn,
      width: 100,
    },
    {
      ...payCycleReportsStatusColumn,
      width: 100,
    },
  ],
})

type Props = CommonTabProps & {
  onClickReport: (id: number) => void
}
export const PayCycleEmployeeReportsTable = ({
  selectedCycle,
  setSelectedCycle,
  cycleSelectorOptions,
  isCycleSelectorLoading,
  onClickReport,
}: Props) => {
  const params = useParams<{ id: string }>()
  const cycleId = selectedCycle?.id || params.id

  const table = useTable<PayCycleReportTableItemInterface, PayCycleReportStatsInterface>(
    getPayCycleEmployeeReportsTableRequests(cycleId),
  )

  useEffect(() => {
    table.refresh()
  }, [cycleId])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Total"
          color={Token.color.greyTone50}
          val={table.stats?.total_reports}
        />
        <Stat
          label={pluralize('Change', table.stats?.total_changes)}
          color={Token.color.greyTone50}
          val={table.stats?.total_changes}
        />
        <Stat
          label={
            table.stats?.total_issues === 0
              ? 'No issues'
              : pluralize('Issue', table.stats?.total_issues)
          }
          color={Token.color.greyTone50}
          val={table.stats?.total_issues}
        />
        <Stat
          label={
            table.stats?.total_criticals === 0
              ? 'No criticals'
              : pluralize('Critical', table.stats?.total_criticals)
          }
          color={
            table.stats?.total_criticals === 0 ? Token.color.success : Token.color.danger
          }
          val={table.stats?.total_criticals}
        />
        <Stat
          label={pluralize('Warning', table.stats?.total_warnings)}
          color={Token.color.warning}
          val={table.stats?.total_warnings}
        />
        <Stat
          label="Approved"
          color={Token.color.success}
          val={table.stats?.total_approved}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <CycleSelector
          value={selectedCycle}
          onChange={setSelectedCycle}
          options={cycleSelectorOptions}
          isLoading={isCycleSelectorLoading}
        />
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.PayCycleEmployeeReports}
          dataType="report"
          row={getRow(onClickReport)}
          useWindowScroll
          emptyState={
            <Box py="s-32">
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
                  }}
                />
                <StatusWidget.Title>Nothing found</StatusWidget.Title>
                <StatusWidget.Description>
                  No employee reports for this cycle
                </StatusWidget.Description>
              </StatusWidget>
            </Box>
          }
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
