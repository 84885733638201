import React from 'react'
import get from 'lodash/get'
import { createChain, Ellipsis, HStack, Icon, Text, Token } from '@revolut/ui-kit'

import { getStatusColor } from '@src/components/CommonSC/General'
import {
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainName,
} from '@src/interfaces/payrollV2'
import { formatDate } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import { IdStatuses } from '@src/interfaces/employees'

export type FieldConfig = {
  path: string
  label?: string
  insert?: (value: string | number) => React.ReactNode
  omitFrom?: boolean
}
export const domainNameToFieldsConfig: Record<
  PayrollTimelineDomainName,
  Array<string | FieldConfig>
> = {
  // Work details:
  name: ['name', 'full_name', 'first_name', 'middle_name', 'last_name'],
  status: [
    {
      path: 'status',
      label: 'Employee status',
      insert: statusId => (
        <Text color={getStatusColor(statusId as IdStatuses)}>
          {formatSnakeCase(String(statusId))}
        </Text>
      ),
    },
  ],
  end_of_probation: ['end_of_probation_date_time'],
  employee_type: ['employee_type'],

  // Contract:
  contract_type: ['contract_type'],
  contract_status: ['contract_status'],
  contract_term: ['contract_term'],
  start_date: ['start_date'],
  end_date: ['end_date'],
  entity: ['entity'],
  location: ['location'],
  salary: [
    'salary_amount',
    'salary_currency',
    'salary_payment_frequency',
    'salary_time_unit',
  ],
  sign_on_bonus: ['sign_on_bonus_amount', 'sign_on_bonus_currency', 'sign_on_bonus_type'],
  position: ['position'],
  specialisation: ['specialisation'],
  seniority: ['seniority', 'specialisation_seniority_sublevel'],
  job_title: ['title'],
  weekly_working_hours: ['weekly_working_hours'],
  full_time_equivalent: ['full_time_equivalent'],
  notice_period_during_probation: [
    'notice_period_during_probation',
    'notice_period_during_probation_unit',
  ],
  notice_period_after_probation: [
    'notice_period_after_probation',
    'notice_period_after_probation_unit',
  ],
  inactivity: ['inactivity_reason', 'inactivity_start_date', 'inactivity_end_date'],
  approval_status: ['approval_status'],

  // Personal details:
  personal_email: ['personal_email'],
  legal_sex: ['legal_sex'],
  marital_status: ['marital_status'],
  birth_date: ['birth_date'],
  phone_number: ['phone_number', 'phone_number_short', 'phone_country_code'],
  nationality: ['nationality'],
  nationalities: ['nationalities'],
  languages: ['languages'],
  address: [
    'country',
    'county',
    'city',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'post_code',
  ],
  emergency_contact: [
    'emergency_contact_full_name',
    'emergency_contact_email',
    'emergency_contact_mobile_phone',
    'emergency_contact_phone_country_code',
    'emergency_contact_phone_number',
    'emergency_contact_relationship',
  ],

  // Time Off:
  time_off_request: [
    'duration',
    'from_date_time',
    { path: 'start_date_time', insert: date => formatDate(date as string) },
    { path: 'end_date_time', insert: date => formatDate(date as string) },
    'total_duration',
    'balance',
    'unit',
  ],
}

const isConfig = (field: FieldConfig | string): field is FieldConfig =>
  typeof field === 'object' && 'path' in field

const isInsertable = (value: unknown): value is string | number =>
  typeof value === 'string' || typeof value === 'number'

const parseNum = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  if (!isNaN(Number(value))) {
    return Number(value)
  }
  return undefined
}

export type ChangeType = 'create' | 'increase' | 'decrease' | 'none' | 'unknown'
export type ParsedDomainFieldChanges = {
  from: React.ReactNode
  to: React.ReactNode
  label: string
  changeType: ChangeType
}
export const parseDomainFieldChanges = (
  data: PayrollTimelineChangeInterface,
  fieldConf: FieldConfig | string,
): ParsedDomainFieldChanges => {
  const changesFrom = data.from_value
  const changesTo = data.to_value

  const fieldPath = isConfig(fieldConf) ? fieldConf.path : fieldConf
  const fieldLabel =
    isConfig(fieldConf) && fieldConf.label
      ? fieldConf.label
      : formatSnakeCase(fieldPath.split('.')[0])

  const omitFrom = isConfig(fieldConf) ? fieldConf.omitFrom : undefined
  const insert = isConfig(fieldConf) ? fieldConf.insert : undefined

  let from
  let to
  let changeType: ChangeType = 'none'

  const nothingChanged = { from, to, label: fieldLabel, changeType: 'none' as const }

  if (!get(changesTo, fieldPath)) {
    return nothingChanged
  }

  const fromValue = omitFrom ? undefined : get(changesFrom, fieldPath)
  const toValue = get(changesTo, fieldPath)

  from = parseNum(fromValue) || fromValue
  to = parseNum(toValue) || toValue

  if (from === to) {
    return nothingChanged
  }
  if (typeof from === 'number' && typeof to === 'number') {
    changeType =
      from < to ? ('increase' as const) : from > to ? ('decrease' as const) : 'none'
  } else if (!from) {
    changeType = 'create'
  } else {
    changeType = 'unknown' as const
  }
  if (insert) {
    from = isInsertable(from) ? insert(from) : from
    to = isInsertable(to) ? insert(to) : to
  }

  return {
    from,
    to,
    label: fieldLabel,
    changeType,
  }
}

type ValuesDiffProps = {
  from: React.ReactNode
  to: React.ReactNode
  type?: ChangeType
}
export const ValuesDiff = ({ from, to, type }: ValuesDiffProps) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const maxPreviewWidth = 96

  return (
    <HStack align="center" space="s-4">
      {type === 'increase' && (
        <Icon name="ArrowUp" size={15} color={Token.color.success} />
      )}
      {type === 'decrease' && (
        <Icon name="ArrowDown" size={15} color={Token.color.warning} />
      )}
      {type === 'unknown' && (
        <Icon mr="s-4" name="ArrowExchange" size={15} color={Token.color.greyTone50} />
      )}
      {arrowChain(
        from ? (
          <Ellipsis maxWidth={maxPreviewWidth}>
            <Text color={Token.color.greyTone50} textDecoration="line-through">
              {from}
            </Text>
          </Ellipsis>
        ) : undefined,
        <Ellipsis maxWidth={from ? maxPreviewWidth : maxPreviewWidth * 2}>
          <Text>{to}</Text>
        </Ellipsis>,
      )}
    </HStack>
  )
}
