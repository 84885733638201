import React, { useState } from 'react'
import addMonths from 'date-fns/addMonths'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import isThisMonth from 'date-fns/isThisMonth'
import startOfMonth from 'date-fns/startOfMonth'
import subMonths from 'date-fns/subMonths'
import {
  Avatar,
  Item,
  ItemSkeleton,
  Text,
  Token,
  VStack,
  Widget,
  chain,
} from '@revolut/ui-kit'

import { useGetPayRollChangeDetails } from '@src/api/payrollV2'
import SideBar from '@src/components/SideBar/SideBar'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { CommonTabProps } from '../common'
import { DetailsSidebarContent } from './DetailsSidebarContent'
import { Month } from './Month'

export const PayCycleTabChanges: React.FC<CommonTabProps> = ({ data }) => {
  const [loadedMonths, setLoadedMonths] = useState<number[]>([])
  const [selectedChangeId, setSelectedChangeId] = useState<number>()

  const cycleStartDate = data?.start_date
  const monthsFromCycleStart = cycleStartDate
    ? differenceInCalendarMonths(new Date(), new Date(cycleStartDate)) || 1
    : 1
  const firstDaysByMonths = new Array(monthsFromCycleStart)
    .fill(null)
    .map((_, i) => startOfMonth(subMonths(new Date(), i)))

  const {
    data: changeDetails,
    isLoading: isLoadingChangeDetails,
    isError: isChangeDetailsError,
  } = useGetPayRollChangeDetails(selectedChangeId)
  const selectedEmployee = changeDetails?.employee

  return (
    <>
      <Widget maxWidth={Token.breakpoint.md}>
        <VStack space="s-8">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="16/ListBullet" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Pay group changes overview</Item.Title>
            </Item.Content>
          </Item>
          {firstDaysByMonths.map(startOfMonthDate => {
            return (
              <Month
                key={startOfMonthDate.valueOf()}
                payGroupId={data?.pay_group.id}
                startOfMonthDate={startOfMonthDate}
                allowLoading={
                  // BE doesn't paginate data, so for requests optimization
                  // we load months one by one, while checking if they are already in the viewport
                  isThisMonth(startOfMonthDate) ||
                  loadedMonths.includes(addMonths(startOfMonthDate, 1).valueOf())
                }
                onLoaded={dateString => {
                  setLoadedMonths([...loadedMonths, dateString])
                }}
                onClick={id => setSelectedChangeId(id)}
              />
            )
          })}
        </VStack>
      </Widget>
      <SideBar
        title=""
        variant="wide"
        sideProps={{ resizable: true }}
        isOpen={!!selectedChangeId}
        onClose={() => setSelectedChangeId(undefined)}
      >
        {isLoadingChangeDetails ? (
          <VStack space="s-16">
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        ) : (
          <VStack space="s-20">
            {selectedEmployee ? (
              <Item>
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(selectedEmployee.avatar)}
                    label={getInitials(selectedEmployee.full_name || '')}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text>{selectedEmployee.full_name}</Text>
                  </Item.Title>
                  {!!(selectedEmployee.job_title || selectedEmployee.seniority) && (
                    <Item.Description>
                      <Text color={Token.color.greyTone50}>
                        {chain(
                          selectedEmployee.job_title,
                          selectedEmployee.seniority?.name,
                        )}
                      </Text>
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
            ) : (
              <ItemSkeleton />
            )}
            <DetailsSidebarContent
              isLoading={isLoadingChangeDetails}
              isError={isChangeDetailsError}
              timelineChanges={changeDetails ? [changeDetails] : undefined}
              onClose={() => setSelectedChangeId(undefined)}
            />
          </VStack>
        )}
      </SideBar>
    </>
  )
}
