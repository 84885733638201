import { Flex, Token, Text, Circle, Icon, Box, Tag } from '@revolut/ui-kit'
import {
  FinalGrade,
  PerformanceType,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import React, { ReactNode } from 'react'
import { gradeSettings } from './utils'
import { formatDate } from '@src/utils/format'
import { isFuture } from 'date-fns'

interface Props {
  type: PerformanceType | undefined
  actionsElements: ReactNode
  reviewsElements: ReactNode
  finalGrade: FinalGrade | undefined
  publishedResults: SingleTimelineEventInterface[]
  isEmptyReviewers: boolean
}

export const PerfomanceStepDetails = ({
  type,
  actionsElements,
  reviewsElements,
  finalGrade,
  publishedResults,
  isEmptyReviewers,
}: Props) => {
  const renderContent = () => {
    if (type === 'review_results') {
      return (
        <Flex paddingRight="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
          <Flex gap="s-16">
            {finalGrade ? (
              <Circle bg={Token.color.success} size={40}>
                <Icon name="Trophy" color={Token.color.onAccent} size={24} />
              </Circle>
            ) : (
              <Circle bg={Token.color.greyTone10} size={40}>
                <Icon name="16/SandWatch" color={Token.color.onAccent} size={24} />
              </Circle>
            )}
            <Flex gap="s-2" flexDirection="column">
              <Text whiteSpace="nowrap" variant="emphasis1">
                {finalGrade
                  ? gradeSettings[finalGrade].title
                  : 'Grade not yet published '}
              </Text>
              {finalGrade && (
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  Congratulations, you’ve achieved {gradeSettings[finalGrade].title} grade
                </Text>
              )}
              {publishedResults.map(result => {
                return (
                  <Flex alignItems="center" mt="s-8" gap="s-4" key={result.title}>
                    <Icon
                      color={Token.color.greyTone50}
                      name={
                        isFuture(new Date(result.start_period))
                          ? '16/SandWatch'
                          : '16/CheckCircle'
                      }
                      size={16}
                    />
                    <Text
                      color={Token.color.greyTone50}
                      whiteSpace="nowrap"
                      variant="body2"
                    >
                      {result.title}: {formatDate(result.start_period, 'dd MMM')}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>

          {finalGrade && (
            <Tag variant="outlined" color={Token.color.success}>
              <Icon mr="s-4" name="Check" size={12} />
              Completed
            </Tag>
          )}
        </Flex>
      )
    }
    if (type && ['kpi', 'goal', 'review', 'calibration'].includes(type)) {
      if (isEmptyReviewers) {
        return (
          <Box px="s-16" width="100%">
            {reviewsElements || <Text>N/A</Text>}
          </Box>
        )
      }
      return (
        <Box width="100%">
          <Flex flexDirection="column" gap="s-6">
            {reviewsElements}
          </Flex>
        </Box>
      )
    }
    return (
      <Flex paddingRight="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
        <Flex gap="s-16">
          <Circle bg={Token.color.greyTone10} size={40}>
            <Icon name="Megaphone" color={Token.color.accent} size={24} />
          </Circle>
          <Flex gap="s-2" flexDirection="column">
            <Text whiteSpace="nowrap" variant="emphasis1">
              Nominate for promotion
            </Text>
            <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
              Line managers and Functional managers can nominate
            </Text>
          </Flex>
        </Flex>
        {actionsElements}
      </Flex>
    )
  }

  return (
    <Flex style={{ flexGrow: 1 }} justifyContent="space-between">
      {renderContent()}
    </Flex>
  )
}
