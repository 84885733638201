import React from 'react'
import {
  DetailsCell,
  ItemSkeleton,
  StatusWidget,
  TextButton,
  VStack,
  Text,
  Token,
  Group,
  Box,
} from '@revolut/ui-kit'

import {
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainCategory,
  PayrollTimelineDomainName,
} from '@src/interfaces/payrollV2'
import { formatSnakeCase } from '@src/utils/string'
import {
  domainNameToFieldsConfig,
  ParsedDomainFieldChanges,
  parseDomainFieldChanges,
  ValuesDiff,
} from './common'

type DomainChangesWidgetProps = {
  name: PayrollTimelineDomainName
  category: PayrollTimelineDomainCategory
  fieldsChanges: ParsedDomainFieldChanges[]
}
const DomainChangesWidget = ({
  name,
  category,
  fieldsChanges,
}: DomainChangesWidgetProps) => {
  if (!fieldsChanges.length) {
    return null
  }
  return (
    <Box>
      <Text use="div" variant="h6" color={Token.color.greyTone50} mb="s-8">
        {formatSnakeCase(name)} in {formatSnakeCase(category)}
      </Text>
      <Group>
        {fieldsChanges.map(parsedField => {
          const { from, to, label, changeType } = parsedField
          return (
            <DetailsCell key={label}>
              <DetailsCell.Title>{label}</DetailsCell.Title>
              <DetailsCell.Content>
                <ValuesDiff from={from} to={to} type={changeType} />
              </DetailsCell.Content>
            </DetailsCell>
          )
        })}
      </Group>
    </Box>
  )
}

type Props = {
  isLoading: boolean
  isError: boolean
  timelineChanges: PayrollTimelineChangeInterface[] | undefined
  onClose: () => void
}
export const DetailsSidebarContent = ({
  isLoading,
  isError,
  timelineChanges,
  onClose,
}: Props) => {
  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  if (isError) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D083.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D083@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D083@3x.png',
          }}
        />
        <StatusWidget.Title>Something went wrong</StatusWidget.Title>
        <StatusWidget.Description>Please, try again later</StatusWidget.Description>
        <StatusWidget.Action>
          <TextButton onClick={onClose}>Close</TextButton>
        </StatusWidget.Action>
      </StatusWidget>
    )
  }

  const detailsWidgets = timelineChanges
    ?.map(change => {
      const fieldsConfig = domainNameToFieldsConfig[change.domain_name] || []
      return {
        change,
        fieldsChanges: fieldsConfig
          .map(cfg => parseDomainFieldChanges(change, cfg))
          .filter(conf => conf.changeType !== 'none'),
      }
    })
    .filter(widgetProps => !!widgetProps.fieldsChanges.length)

  if (!detailsWidgets?.length) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
          }}
        />
        <StatusWidget.Title>No changes found for this employee</StatusWidget.Title>
        <StatusWidget.Action>
          <TextButton onClick={onClose}>Close</TextButton>
        </StatusWidget.Action>
      </StatusWidget>
    )
  }

  return (
    <VStack space="s-20">
      {detailsWidgets?.map(widget => {
        const { change, fieldsChanges } = widget
        return (
          <DomainChangesWidget
            key={change.id}
            name={change.domain_name}
            category={change.domain_category}
            fieldsChanges={fieldsChanges}
          />
        )
      })}
    </VStack>
  )
}
