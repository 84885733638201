import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'
import React, { useEffect, useState } from 'react'
import {
  PerformanceScores,
  ScorecardFields,
  ScorecardFieldTypes,
  ScorecardInterface,
} from '@src/interfaces/scorecard'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { getPerformanceReviews } from '@src/api/performance'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import { Box, chain, Flex, Item, Text, Token, VStack } from '@revolut/ui-kit'
import {
  performanceToColor,
  performanceToString,
} from '@src/features/Scorecard/constants'
import { isObject } from 'lodash'
import Tooltip from '@components/Tooltip/Tooltip'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { formatPercentage } from '@src/utils/format'
import { MainScore } from '@src/features/Scorecard/MainScore'
import { FeedbackSection } from '@src/pages/EmployeeProfile/Layout/Performance/FeedbackSection'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
}

const columnNames = [
  PerformanceScores.UNSATISFACTORY,
  PerformanceScores.BELOW_EXPECTATIONS,
  PerformanceScores.MEETS_EXPECTATIONS,
  PerformanceScores.ABOVE_EXPECTATIONS,
  PerformanceScores.EXCEPTIONAL,
  PerformanceScores.NEUTRAL,
]

const getReviewerRelation = (
  reviewer: 'line_manager' | 'self' | 'team_mate' | 'other',
) => {
  switch (reviewer) {
    case 'line_manager':
      return 'LM'
    case 'team_mate':
    case 'other':
      return 'peer'
    default:
      return reviewer
  }
}

export const ObsoletePerformanceReview = ({
  data,
  selectedPeriod,
  feedback,
  refetchFeedbackList,
}: Props) => {
  const [scorecardData, setScorecardData] = useState<ScorecardInterface>()
  const [fetching, setFetching] = useState(false)

  const cycleId = selectedPeriod.id

  const fetchScorecard = async () => {
    setFetching(true)
    const performanceFilter = []

    if (selectedPeriod.category !== ReviewCycleCategory.Probation) {
      performanceFilter.push({
        filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
        columnName: 'cycle__id',
      })
    }

    const result = await getPerformanceReviews(
      data.id,
      filterSortPageIntoQuery(undefined, performanceFilter, undefined),
    )

    if (result.data) {
      setScorecardData(result.data)
      setFetching(false)
    }
  }

  useEffect(() => {
    setScorecardData(undefined)
    fetchScorecard()
  }, [data.id, selectedPeriod.category])

  const row: RowInterface<ScorecardFields> = {
    cells: [
      {
        type: CellTypes.insert,
        idPoint: 'text',
        dataPoint: 'text',
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.none,
        title: 'Name',
        width: 110,
        insert: ({ data: columnData }: { data: ScorecardFields }) => {
          return <Box ml="s-2">{columnData.text}</Box>
        },
      },
      ...columnNames.map(name => ({
        type: CellTypes.insert,
        idPoint: name,
        dataPoint: name,
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.none,
        headerCentered: true,
        title: performanceToString(name),
        insert: ({ data: columnData }: { data: ScorecardFields }) => {
          const values = []

          if (columnData.value && isObject(columnData.value)) {
            const filteredValues = Object.entries(columnData.value)
              .filter(entry => entry[0] === name)
              .map(entry => entry[1])
            values.push(...filteredValues)
          }
          const checked = !!values.length

          return (
            <Tooltip
              placement="left"
              hide={!checked}
              title={performanceToString(name)}
              text={values
                .map(value =>
                  value.map((reviewer: { full_name: string }) => reviewer.full_name),
                )
                .join('\\n')}
            >
              <Flex justifyContent="center" alignItems="center" gap="s-2">
                {checked && <Text>{values.length}</Text>}
                <RadioButton checked={checked} onChange={() => {}} disabled />
              </Flex>
            </Tooltip>
          )
        },
        width: 60,
      })),
    ],
  }

  const renderScore = (label?: PerformanceScores, score?: number) => {
    return (
      <Box color={performanceToColor(label)}>
        {chain(
          <Text variant="caption">{formatPercentage(score || null)}</Text>,
          <Text variant="caption">{performanceToString(label)}</Text>,
        )}
      </Box>
    )
  }

  return (
    <>
      <MainScore
        performanceLabel={scorecardData?.performance_score_label}
        performancePercentage={scorecardData?.performance_score}
        onEditClick={() => {}}
        showEditing={false}
      />
      {fetching ? (
        <SectionLoader />
      ) : (
        <Box>
          {!!scorecardData?.scorecards?.length && (
            <Box>
              {scorecardData.scorecards.map((scorecard, i) => {
                const tableFields = scorecard.fields.filter(
                  card => card.card_type === ScorecardFieldTypes.thumbs,
                )
                return (
                  <VStack mb="s-12" key={i}>
                    <CollapsableTableWrapper
                      headerTitle={scorecard.title}
                      headerStat={scorecard.score}
                    >
                      {!!tableFields.length && (
                        <Flex style={{ flexGrow: 1 }}>
                          <AdjustableTable<ScorecardFields>
                            row={row}
                            data={tableFields}
                            count={tableFields.length}
                            name={TableNames.ObsoletePerformanceReview}
                          />
                        </Flex>
                      )}
                    </CollapsableTableWrapper>
                  </VStack>
                )
              })}
            </Box>
          )}
        </Box>
      )}
      <FeedbackSection
        data={data}
        feedback={feedback}
        refetchFeedbackList={refetchFeedbackList}
        performanceContent={
          scorecardData?.feedback.length ? (
            <>
              {scorecardData?.feedback.map((feedbackItem, i) => {
                return (
                  <Box
                    key={i}
                    mb="s-16"
                    border={`1px solid ${Token.color.greyTone10}`}
                    borderRadius={16}
                  >
                    <Item>
                      <Item.Content>
                        <Item.Title>{`${feedbackItem.full_name} (${getReviewerRelation(
                          feedbackItem.reviewer_relation,
                        )})`}</Item.Title>
                        <Item.Description whiteSpace="break-spaces" mt="s-16">
                          {feedbackItem.text}
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <Item.Value>
                          {renderScore(feedbackItem.score_label, feedbackItem.score)}
                        </Item.Value>
                      </Item.Side>
                    </Item>
                  </Box>
                )
              })}
            </>
          ) : undefined
        }
      />
    </>
  )
}
