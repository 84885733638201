import { ReviewCategory } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { ProbationCheckpoint, ProbationResults } from '@src/interfaces/probationReview'
import {
  ActionButton,
  Avatar,
  Flex,
  Icon,
  IconName,
  ItemSkeleton,
  Text,
  Token,
  useTooltip,
} from '@revolut/ui-kit'
import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useGetCommitteeResult } from '@src/api/probationReview'
import { notReachable } from '@src/utils/notReachable'

enum ButtonStates {
  Add = 'Add',
  View = 'View',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Decision',
  [ButtonStates.Edit]: 'Edit Decision',
  [ButtonStates.View]: 'View Decision',
}

const ButtonIcon: { [key: string]: IconName } = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
}

const ReviewResultPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_COMMITTEE_RESULT.GENERAL,
}

const getProbationResultSettings = (result: ProbationResults) => {
  switch (result) {
    case ProbationResults.Passed:
      return { color: Token.color.green, title: 'Passed' }
    case ProbationResults.Extend:
      return { color: Token.color.warning, title: 'Extended' }
    case ProbationResults.ChangeSeniority:
      return { color: Token.color.greyTone50, title: 'Change Seniority' }
    case ProbationResults.Failed:
      return { color: Token.color.danger, title: 'Failed' }
    default:
      return notReachable(result)
  }
}

export const CommitteeStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
}) => {
  const { data: decision, isLoading } = useGetCommitteeResult(
    reviewCategory,
    employeeId,
    checkpoint?.decision?.id,
    cycleId,
  )
  const tooltip = useTooltip()

  const buttonDisabled = !checkpoint?.can_be_submitted && !checkpoint?.decision

  const onClickAddReview = () => {
    navigateTo(
      pathToUrl(ReviewResultPath[reviewCategory], {
        employeeId,
        cycleId,
        id: checkpoint?.decision?.id,
      }),
    )
  }

  let state = ButtonStates.View
  if (checkpoint?.can_be_submitted) {
    state = !checkpoint.decision ? ButtonStates.Add : ButtonStates.Edit
  }

  return (
    <>
      {isLoading ? (
        <ItemSkeleton />
      ) : (
        <Flex style={{ flexGrow: 1 }} paddingRight="s-20" justifyContent="space-between">
          <Flex gap="s-16">
            <Avatar bg={Token.color.accent}>
              <Icon color={Token.color.onAccent} name="People" />
              <Avatar.Badge
                bg={checkpoint.decision ? Token.color.green : Token.color.orange}
                position="bottom-right"
                useIcon={checkpoint.decision ? 'Check' : '16/SandWatch'}
              />
            </Avatar>
            <Flex gap="s-2" flexDirection="column">
              <Text whiteSpace="nowrap" variant="emphasis1">
                HR Committee
              </Text>
              <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                HR Committee to review results of the
                {reviewCategory === ReviewCategory.Probation ? ' probation ' : ' PIP '}
                checkpoints.
              </Text>
            </Flex>
          </Flex>
          {!buttonDisabled && (
            <ActionButton
              useIcon={ButtonIcon[state]}
              onClick={onClickAddReview}
              {...tooltip.getAnchorProps()}
            >
              {ButtonTitles[state]}
            </ActionButton>
          )}
          {buttonDisabled && decision?.result && (
            <Text
              variant="body1"
              color={getProbationResultSettings(decision.result).color}
            >
              {getProbationResultSettings(decision.result).title}
            </Text>
          )}
        </Flex>
      )}
    </>
  )
}
