import React from 'react'
import { Avatar, chain, IconName, Item, ItemProps, Text, Token } from '@revolut/ui-kit'

import {
  PayrollTimelineDomainCategory,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { formatDate } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import {
  ChangeType,
  domainNameToFieldsConfig,
  FieldConfig,
  parseDomainFieldChanges,
  ValuesDiff,
} from './common'

const categoryToIcon = (data: PayrollTimelineEventInterface): IconName => {
  const event = data.payroll_event
  const category = data.content.domain_category

  if (event === 'hire') {
    return 'AddContact'
  }
  if (event === 'termination') {
    return 'BrokenHeart'
  }
  if (event === 'data_change') {
    const mapCategoryToIcon: Record<PayrollTimelineDomainCategory, IconName> = {
      work_details: 'Services',
      contract: 'Document',
      personal_details: 'NationalId',
      time_off: 'TimeAndMoney',
    }
    return mapCategoryToIcon[category] || 'QuestionSign'
  }
  return 'QuestionSign'
}

const getTitle = (data: PayrollTimelineEventInterface) => {
  const event = data.payroll_event
  const category = data.content.domain_category

  switch (event) {
    case 'data_change':
      return `${formatSnakeCase(category)} change`
    case 'hire':
      return 'New hire'
    case 'termination':
      return 'Termination'
    default:
      return 'Unknown event'
  }
}

type ChangePreviewValuesProps = {
  from?: React.ReactNode
  to: React.ReactNode | undefined
  label: string
  type?: ChangeType
}
const ChangePreviewValues = ({ label, from, to, type }: ChangePreviewValuesProps) => {
  if (!to) {
    return null
  }
  return (
    <>
      <Item.Value>
        <ValuesDiff from={from} to={to} type={type} />
      </Item.Value>
      {label && (
        <Item.Value>
          <Text variant="caption" color={Token.color.greyTone50}>
            {label}
          </Text>
        </Item.Value>
      )}
    </>
  )
}

type ChangePreviewProps = {
  data: PayrollTimelineEventInterface
  fields: Array<string | FieldConfig>
}
const ChangePreview = ({ data, fields }: ChangePreviewProps) => {
  for (let i = 0; i < fields.length; i++) {
    const { from, to, label, changeType } = parseDomainFieldChanges(
      data.content,
      fields[i],
    )
    // Show first successfully parsed field by config order prio
    if (to && changeType !== 'none') {
      return <ChangePreviewValues label={label} from={from} to={to} type={changeType} />
    }
  }
  return null
}

const getSideValue = (data: PayrollTimelineEventInterface) => {
  if (data.payroll_event === 'hire' || data.payroll_event === 'termination') {
    switch (data.payroll_event) {
      case 'hire': {
        return (
          <ChangePreviewValues
            label="Start date"
            to={formatDate(data.effective_date_time)}
          />
        )
      }
      case 'termination': {
        return (
          <ChangePreviewValues
            label="End date"
            to={formatDate(data.effective_date_time)}
          />
        )
      }
      default:
        return null
    }
  } else if (data.payroll_event === 'data_change') {
    return (
      <ChangePreview
        data={data}
        fields={domainNameToFieldsConfig[data.content.domain_name] || []}
      />
    )
  }
  return null
}

type Props = {
  data: PayrollTimelineEventInterface
  onClick: (id: number) => void
}
export const TimelineItem = ({ data, onClick }: Props) => {
  const itemProps: Pick<ItemProps, 'onClick' | 'use'> =
    data.payroll_event === 'data_change'
      ? { use: 'button', onClick: () => onClick(data.content.id) }
      : {}
  const title = getTitle(data)
  const label = `Preview ${title}: ${formatSnakeCase(data.content.domain_name)}`

  return (
    <Item {...itemProps} aria-label={label}>
      <Item.Avatar>
        <Avatar useIcon={categoryToIcon(data)} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>
          {chain(
            data.content.employee.full_name,
            data.effective_date_time ? formatDate(data.effective_date_time) : undefined,
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>{getSideValue(data)}</Item.Side>
    </Item>
  )
}
